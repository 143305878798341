import { HStack } from "@chakra-ui/react";
import React from "react";
import StatusViewRequestReview from "./StatusViewRequestReview";
import StatusViewUnused from "./StatusViewUnused";
import StatusViewAwaitingPayment from "./StatusViewAwaitingPayment";
import StatusViewFinalized from "./StatusViewFinalized";
import StatusViewEmpty from "./StatusViewEmpty";
import { ACTION_TYPES } from "../../constants";
import { GroupingContainer } from "components/groupingContainer/GroupingContainer";
import Actions from "./Actions";

const getActions = (status) => {
  switch (status) {
    case "awaitingPayment":
      return [
        ACTION_TYPES.VIEW_INVOICE,
        ACTION_TYPES.RESEND_FORM,
        ACTION_TYPES.FUNDS_SENT,
        ACTION_TYPES.REJECT,
      ];
    case "finalized":
      return [
        ACTION_TYPES.VIEW_INVOICE,
        ACTION_TYPES.REJECT,
        ACTION_TYPES.REPROCESS,
      ];
    case "flagged":
    case "rejected":
      return [ACTION_TYPES.MOVE_TO_REQUESTED];
    case "request":
      return [
        ACTION_TYPES.APPROVE,
        ACTION_TYPES.REFUNDED,
        ACTION_TYPES.FLAG,
        ACTION_TYPES.REJECT,
        ACTION_TYPES.MOVE_TO_COMPLETED,
        ACTION_TYPES.EDIT_PAYMENT_AMOUNT,
        ACTION_TYPES.MOVE_TO_REVIEW,
      ];
    case "review":
      return [ACTION_TYPES.REVIEW];
    case "unused":
      return [ACTION_TYPES.RESEND_FORM, ACTION_TYPES.REJECT];
    default:
      return [];
  }
};
const StatusView = ({ payoutStatus, data }) => {
  const actions = getActions(payoutStatus);
  return (
    <HStack h="fit-content" alignItems={"stretch"} spacing={2}>
      {payoutStatus === "awaitingPayment" && (
        <StatusViewAwaitingPayment data={data} />
      )}
      {payoutStatus === "finalized" && <StatusViewFinalized data={data} />}
      {payoutStatus === "flagged" && <StatusViewEmpty data={data} />}
      {payoutStatus === "rejected" && <StatusViewEmpty data={data} />}
      {payoutStatus === "request" && <StatusViewRequestReview data={data} />}
      {payoutStatus === "review" && <StatusViewRequestReview data={data} />}
      {payoutStatus === "unused" && <StatusViewUnused data={data} />}
      {actions.length && (
        <GroupingContainer flex={0} title={"Actions"}>
          <Actions actions={actions} paymentData={data} />
        </GroupingContainer>
      )}
    </HStack>
  );
};

export default StatusView;
