export const STATUS_TYPES = {
  REQUEST: { DISPLAY_TEXT: "Requested", API_KEY: "request" },
  REJECTED: { DISPLAY_TEXT: "Rejected", API_KEY: "rejected" },
  UNUSED: { DISPLAY_TEXT: "Form Sent", API_KEY: "unused" },
  AWAITING_PAYMENT: { DISPLAY_TEXT: "Form Filled", API_KEY: "awaitingPayment" },
  FINALIZED: { DISPLAY_TEXT: "Completed", API_KEY: "finalized" },
  FLAGGED: { DISPLAY_TEXT: "Flagged", API_KEY: "flagged" },
  REVIEW: { DISPLAY_TEXT: "Review", API_KEY: "review" },
};
export const checkboxStatusItems = Object.keys(STATUS_TYPES).map((item) => ({
  value: STATUS_TYPES[item].API_KEY,
  title: STATUS_TYPES[item].DISPLAY_TEXT,
}));

export const EXPANDABLE_ROW_TYPES = {
  LIST: "List",
  DETAILED: "Detailed",
  COPY_TRADE_REPORT: "CopyTradeReport",
};

export const ACTION_TYPES = {
  APPROVE: "Approve",
  REVIEW: "Review",
  EDIT_PAYMENT_AMOUNT: "Modify Amount",
  MOVE_TO_REVIEW: "Move to Review",
  FLAG: "Flag",
  FUNDS_SENT: "Funds Sent",
  MOVE_TO_COMPLETED: "Move to Completed",
  MOVE_TO_REQUESTED: "Move to Requested",
  REFUNDED: "Refunded",
  REPROCESS: "Reprocess",
  REJECT: "Reject",
  RESEND_FORM: "Resend Form",
  UNUSED: "Unused",
  VIEW_INVOICE: "View Invoice",
};

export const PAYMENT_PURPOSE = {
  Researcher: "Researcher Payout",
  Affiliate: "Affiliate Payout",
  Promoter: "Promoter Payout",
  Support: "Support Staff Payout",
  Technology: "Technology Staff Payout",
  Management: "Management Staff Payout",
  IFX: "IFX Test Payout",
};

export const FILTER_TYPES = {
  STATUS: "status",
};

export const PAYMENT_STATUS_TYPES = {
  request: {
    label: STATUS_TYPES.REQUEST.DISPLAY_TEXT,
    colorScheme: "blue",
  },
  rejected: {
    label: STATUS_TYPES.REJECTED.DISPLAY_TEXT,
    colorScheme: "red",
  },
  unused: {
    label: STATUS_TYPES.UNUSED.DISPLAY_TEXT,
    colorScheme: "yellow",
  },
  awaitingPayment: {
    label: STATUS_TYPES.AWAITING_PAYMENT.DISPLAY_TEXT,
    colorScheme: "orange",
  },
  finalized: {
    label: STATUS_TYPES.FINALIZED.DISPLAY_TEXT,
    colorScheme: "green",
  },
  flagged: {
    label: STATUS_TYPES.FLAGGED.DISPLAY_TEXT,
    colorScheme: "red",
  },
  review: {
    label: STATUS_TYPES.REVIEW.DISPLAY_TEXT,
    colorScheme: "orange",
  },
};
